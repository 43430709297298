<template>
  <list>
    <div class="px-3 px-md-12">
      <v-row class="mt-4">
        <v-col cols="12">
          <v-tabs color="black" v-model="tab" centered slider-color="black">
            <v-tab class="model__selections__tab">LIST VIEW</v-tab>
            <v-tab
              v-if="
                !$vuetify.breakpoint.sm &&
                !$vuetify.breakpoint.xs &&
                !$vuetify.breakpoint.md
              "
              class="model__selections__tab"
              >COMPARE VIEW</v-tab
            >
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- list tab -->
            <div class="d-flex flex-wrap justify-space-between align-center">
              <div>
                <div class="mt-4 d-flex flex-wrap align-center">
                  <div class="text-uppercase mr-3 shooting__date">
                    Shooting date:
                  </div>
                  <div>
                    <v-item-group v-model="filter.dates.val">
                      <v-item
                        v-slot="{ active, toggle }"
                        class="mr-2"
                        v-for="item in shootingDates"
                        :key="item.id"
                        :value="item.date"
                      >
                        <block-button
                          :style="
                            active
                              ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                              : ''
                          "
                          :text="item.text"
                          :class="[
                            active ? 'active-btn' : '',
                            'btn-intended mb-2  pa-4 btn__size__14',
                          ]"
                          @click.native="toggle"
                          height="27"
                        ></block-button>
                      </v-item>
                    </v-item-group>
                  </div>
                </div>
              </div>
              <div>
                <block-button
                  text="Advance FILTER"
                  :text-mode="true"
                  height="27"
                  class="btn__size__16 btn__filter__advance"
                  icon="WMi-filter"
                  color="black"
                  bg-color="white"
                  @click.native="$_openModal('advance_filter', { models })"
                ></block-button>
              </div>
            </div>
            <v-tab-item>
              <v-row class="mt-0">
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    :headers="headers"
                    :items="desserts"
                    :items-per-page="5"
                  >
                    <template v-slot:item.first_name="{ item }">
                      <v-row class="wa__table">
                        <v-col cols="6" md="5">
                          <!-- <div
                            class="model__offer__img"
                            :style="{
                              backgroundImage: `url(${item.thumbnail})`,
                            }"
                          ></div> -->
                          <div class="wa__model__name--image">
                            <figure>
                              <img
                                v-if="item.thumbnail"
                                :src="item.thumbnail"
                                alt="image"
                              />
                              <img
                                v-else
                                src="https://via.placeholder.com/55x65"
                                alt="image"
                              />
                            </figure>
                          </div>
                        </v-col>
                        <v-col cols="6" md="7" class="bg-white px-0">
                          <div
                            class="
                              d-flex
                              align-center
                              justify-content-start
                              h-100
                              special__border
                            "
                          >
                            <div>
                              <div
                                class="
                                  wa__f__m__r
                                  text-uppercase
                                  d-flex
                                  align-center
                                  model__selection__name
                                "
                              >
                                {{ $_changeModelProperty(item, "first_name") }}
                              </div>
                              <div class="d-flex">
                                <div>
                                  <block-button
                                    :class="`wa__f__m__r btn__approve pa-2  ${
                                      item.status.id === 5
                                        ? 'cyan--text'
                                        : 'black--text'
                                    }`"
                                    text="APPROVE"
                                    :disabled="item.status.id === 5"
                                    @click.native="changeStatus(item, 5)"
                                    :loading="item.loadingApproveBtn"
                                  >
                                  </block-button>
                                </div>
                                <div class="ml-1">
                                  <v-btn
                                    text
                                    icon
                                    small
                                    color="red lighten-2"
                                    :loading="item.loadingRejectBtn"
                                  >
                                    <v-icon
                                      class="WMi-cancel-linear"
                                      small
                                      color="#EE3552"
                                      @click.native="changeStatus(item, 6)"
                                    ></v-icon>
                                  </v-btn>
                                </div>
                              </div>
                              <a
                                :href="`${$baseUrl}/print/${item.unique_id}`"
                                target="_blank"
                              >
                                <block-button
                                  text="COMPOSITE"
                                  :text-mode="true"
                                  color="black"
                                  class="
                                    btn__composite btn__size__10
                                    float-left
                                    pl-0
                                  "
                                  icon="WMi-right-chevron"
                                  height="23"
                                ></block-button>
                              </a>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- /list tab -->
            <!-- compare tab -->
            <v-tab-item
              v-if="
                !$vuetify.breakpoint.sm &&
                !$vuetify.breakpoint.xs &&
                !$vuetify.breakpoint.md
              "
            >
              <v-row class="mt-4 compare__list" v-if="filterModels.length">
                <v-col
                  cols="12"
                  v-for="(model, i) in filterModels"
                  :key="i"
                  :md="i % 5 === 0 ? 4 : 2"
                  class="mb-2 pa-0 compare__list--item"
                >
                  <CompareListItem :model="model" :is-first="i % 5 === 0" />
                </v-col>
              </v-row>
              <div v-else class="py-10">
                <div class="text-center">There are no items to display</div>
              </div>
            </v-tab-item>
            <!-- compare tab -->
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
        ---------------------------------------------------------------------->
    <template>
      <div class="text-center">
        <AdvanceFilterModal
          v-if="isModal('modal_advance_filter')"
          @filter="replaceModel($event)"
          @clear="clearFilter"
        />
      </div>
    </template>
    <!-- --------------------------------------------------------------------
                                    Modals :: END
        ---------------------------------------------------------------------->
  </list>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CompareListItem from "./CompareList/Item.vue";
import { TokenStorage, UserStorage } from "@/utils/storage";
import AdvanceFilterModal from "./Modal/AdvanceFilter.vue";
import { cloneDeep } from "lodash";
import dynamicFilter from "@/utils/filter/dynamicFilter";
import JobOfferRepository from "@/abstraction/repository/jobOfferRepository";
let jobOfferRepository = new JobOfferRepository();
const defaultFilter = {
  dates: {
    type: "in",
    val: null,
    key: "date",
  },
};
export default {
  props: ["unique_id"],
  components: {
    CompareListItem,
    AdvanceFilterModal,
  },
  data: () => ({
    tab: null,
    loadingApproveBtn: false,
    loadingRejectBtn: false,
    models: [],
    filterModels: [],
    filter: cloneDeep(defaultFilter),
    shootingDates: [],
  }),
  computed: {
    ...mapGetters("modal", ["isModal"]),
    ...mapGetters("jobOffer", ["getResponse"]),
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        {
          text: this.$_t("age"),
          align: "start",
          sortable: true,
          value: "age",
        },
        {
          text: this.$_t("height"),
          value: "height",
          sortable: true,
          align: "center",
        },
        {
          text: this.$_t("bust"),
          value: "bust",
          sortable: true,
          align: "center",
        },
        {
          text: this.$_t("waist"),
          value: "waist",
          sortable: true,
          align: "center",
        },
        {
          text: this.$_t("hips"),
          value: "hips",
          sortable: true,
          align: "center",
        },
        {
          text: this.$_t("shoe"),
          value: "shoe",
          sortable: true,
          align: "center",
        },
        {
          text: this.$_t("hair"),
          value: "hair",
          sortable: true,
          align: "center",
        },
        {
          text: this.$_t("eye"),
          value: "eye",
          sortable: true,
          align: "center",
        },
        {
          text: this.$_t("nationality"),
          value: "nationality",
          sortable: true,
          align: "center",
        },
        {
          text: this.$_t("language"),
          value: "language",
          sortable: true,
          align: "center",
        },
      ];
    },
    desserts() {
      var arr = [];
      this.filterModels.map((x) => {
        let object = {};
        object["first_name"] = x.user?.first_name;
        object["first_name_jpn"] = x.user?.first_name_jpn;
        object["age"] = x.user?.age;
        object["id"] = x.id;
        object["thumbnail"] = x.user?.model?.thumbnail;
        object["height"] = x.user?.model?.height;
        object["loadingApproveBtn"] = false;
        object["loadingRejectBtn"] = false;
        object["bust"] = x.user?.model?.bust;
        object["waist"] = x.user?.model?.waist;
        object["hips"] = x.user.model?.hips;
        object["shoe"] = x.user.model?.shoes;
        object["hair"] = x.user?.model?.hair_color?.name;
        object["eye"] = x.user?.model?.eye_color?.name;
        object["nationality"] = x.user?.country?.name;
        object["language"] = x.user?.languagesWithLevel
          .map((lang) => lang.name)
          .join(", ");
        object["status"] = x.status;
        object["unique_id"] = x.user?.unique_id;

        arr.push(object);
      });
      return arr;
    },
  },
  watch: {
    "filter.dates.val"(val) {
      console.log(this.models);
      if (!val) return (this.filterModels);
      let newModels = new dynamicFilter(this.filterModels, this.filter);
      this.filterModels = newModels;
    },
  },
  methods: {
    ...mapActions("jobOffer", ["loadResponsesWithUniqueId"]),
    ...mapActions("jobOffer", ["changeStatusResponseClient"]),
    async changeStatus(model, statusId) {
      const indexModel = this.desserts.findIndex((m) => m.id === model.id);
      try {
        if (statusId === 5) {
          this.desserts[indexModel].loadingApproveBtn = true;
        } else {
          this.desserts[indexModel].loadingRejectBtn = true;
        }
        model.newResponseId = statusId;
        await this.changeStatusResponseClient(model);
      } catch (error) {
        return error;
      } finally {
        this.desserts[indexModel].loadingApproveBtn = false;
        this.desserts[indexModel].loadingRejectBtn = false;
      }
    },
    checkUser() {
      const loggedIn = !!TokenStorage.getToken();
      const typeUser = UserStorage.get().type;
      if (!loggedIn || typeUser !== "client") {
        this.redirectToClientRegister();
      }
    },
    async loadResponses() {
      const responseCode = await this.loadResponsesWithUniqueId(this.unique_id);
      if (responseCode === 403 || responseCode === 401)
        this.redirectToClientRegister();
    },
    redirectToClientRegister() {
      this.$router.push({
        name: "clientRegister",
        params: { locale: this.$_getlocaleParam() },
      });
    },
    replaceModel(models) {
      this.filterModels = models;
    },
    clearFilter() {
      this.filterModels = this.models;
    },
    async loadJobOffer() {
      let resource = await jobOfferRepository.showJobOfferWithUniqueId(
        this.unique_id
      );
      let allDates = [];
      resource.dates.map((date) => {
        allDates.push(date.date);
      });
      this.shootingDates = resource["dates"];
      this.shootingDates.unshift({
        date: allDates,
        id: 2,
        text: "All Dates",
      });
      console.log(this.shootingDates, allDates);
    },
  },
  async created() {
    this.checkUser();
    await this.loadJobOffer();
    await this.loadResponses();
    this.models = this.getResponse;

    this.filterModels = cloneDeep(this.models);
  },
};
</script>
<style>
.btn-intended {
  font-family: "montserrat-light";
  border: 1px solid var(--color-light-gray) !important;
  background-color: white !important;
  font-weight: bold;
}
.active-btn {
  border: 1px solid var(--color-black) !important;
}
.shooting__date {
  color: #6c6c6c;
  font-family: "Montserrat-Light";
}
.btn__filter__advance {
  font-family: "Montserrat-ExtraLight" !important;
}
.model__card {
  height: 91px;
  border-bottom: 1px solid #b7b7b7;
  display: flex;
  overflow: hidden;
}
.model__card .row .col {
  height: 100%;
}
.model__card .wa__table {
  height: -webkit-fill-available;
}
.model__card:nth-child(1) {
  margin-top: 14px !important;
}
.model__selections__tab {
  font-family: "montserrat-regular";
}
.theme--light.v-tabs > .v-tabs-bar {
  background-color: rgba(255, 1, 1, 0) !important;
  border-bottom: 1px solid#B7B7B7;
}
.v-tabs-slider-wrapper {
  height: 1px !important;
}
.v-tab:not(.v-tab--active) {
  color: #c3c3c3 !important;
}
.model__offer__img {
  height: 90px;
  background-size: cover;
  background-position: center;
}
.table__title {
  font-family: "montserrat-regular";
  font-size: 8px;
  /* word-break: break-word; */
}
.col {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
/* .col:not(:first-child):not(:last-child) {
  border-right: 1px solid #b7b7b7 !important;
} */
.table__content {
  font-family: "montserrat-regular";
  font-size: 14px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
/* .wa__table {
  border-bottom: 1px solid #b7b7b7;
} */
.wa__table .col {
  height: inherit;
}
.wa__table .col:nth-child(even) {
  background-color: #f9f9f9;
}
.compare__list .compare__list--item:nth-child(odd) table {
  background-color: #f9f9f9 !important;
}
.compare__list .compare__list--item:nth-child(odd) table.is_first {
  background: linear-gradient(
    270deg,
    #f9f9f9,
    #f9f9f9 50%,
    white 49%,
    white 51%,
    white 51%
  );
}
.special__border {
  border-top: 1px solid #e0e0e0;
  margin-top: -1px;
}
.wa__model__name--image {
  height: 92px;
  /* width: 145px; */
}
/* .v-list-item__title {
  font-family: "montserrat-light";
  color: var(--color-black) !important;
  font-weight: 300;
  letter-spacing: 5px;
} */
.wa__model__name--image figure img {
  height: 92px;
  width: auto;
}
.model__selection__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.btn__approve {
  border: 1px solid var(--color-gray) !important;
  background-color: var(--color-white) !important;
  border-radius: 0 !important;
  transition: all 0.4s;
  font-size: 12px !important;
}
.btn__approve:hover {
  border: 1px solid var(--color-gray) !important;
  background-color: var(--color-black) !important;
  color: var(--color-white) !important;
}
.btn__composite span i {
  transform: rotate(90deg);
}
</style>
<style>
tbody tr td:first-child {
  padding-left: 0 !important;
  border-right: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  background-color: transparent !important;
}
tbody tr td {
  font-family: "montserrat-regular";
  font-size: 14px;
}
tbody tr td:nth-child(odd) {
  background-color: #f9f9f9;
  border-right: 1px solid #acacac;
  border-left: 1px solid #acacac;
}
thead.v-data-table-header tr th {
  font-family: "montserrat-regular";
  font-size: 10px !important;
  text-transform: uppercase;
  position: relative;
  /* word-break: break-word; */
}
/* thead.v-data-table-header tr th span {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
} */
.v-data-table__mobile-table-row .v-data-table__mobile-row {
  padding: 0 !important;
}
.v-data-table__mobile-table-row .v-data-table__mobile-row:first-child {
  justify-content: start;
  flex-flow: row-reverse;
}
/* disabled hover */
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}
.v-data-table__wrapper {
  padding: 0 4px !important;
}
.v-btn__loader
  .v-progress-circular.v-progress-circular--visible.v-progress-circular--indeterminate {
  height: 16px !important;
  width: 16px !important;
}
/* pagination */
.v-data-footer {
  font-family: "Montserrat-regular";
  font-size: 14px !important;
}
.v-data-footer__select .v-select__selections .v-select__selection--comma {
  font-size: 1.05rem !important;
}
@media screen and (max-width: 600px) {
  thead tr th {
    padding: 0 !important;
  }
  tbody tr {
    margin-top: 20px !important;
  }
  .v-data-table-header-mobile__wrapper {
    margin-bottom: 10px !important;
  }
  .special__border {
    border-top: none;
    margin: 0;
  }
  tbody tr td:first-child {
    background: transparent !important;
  }
  tbody tr td:nth-child(odd) {
    background-color: #f9f9f9;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    border-bottom: none !important;
  }
  .model__card {
    border-bottom: none;
  }
  .v-chip .v-chip__content {
    display: contents;
  }
}
</style>
