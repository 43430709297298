<template>
  <div>
    <table
      :class="['table', { is_first: isFirst }]"
      :style="isFirst ? 'direction: rtl' : ''"
    >
      <thead>
        <tr>
          <div class="wa__tile--link">
            <div class="position-relative">
              <a href="">
                <picture>
                  <img
                    data-async-image="true"
                    :src="model.user.model.thumbnail"
                    class="model__image pa-3"
                    decoding="async"
                /></picture>
              </a>
            </div>
            <div class="model__name mb-6">
              {{ $_changeModelProperty(model.user, "first_name") }}
            </div>
          </div>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ model.user.age }}</td>
          <th scope="row" v-if="isFirst">{{ $_t("age") }}</th>
        </tr>
        <tr>
          <td>{{ model.user.model.height }}</td>
          <th scope="row" v-if="isFirst">{{ $_t("height") }}</th>
        </tr>
        <tr>
          <td>{{ model.user.model.bust }}</td>
          <th scope="row" v-if="isFirst">{{ $_t("bust") }}</th>
        </tr>
        <tr>
          <td>{{ model.user.model.waist }}</td>
          <th scope="row" v-if="isFirst">{{ $_t("waist") }}</th>
        </tr>
        <tr>
          <td>{{ model.user.model.hips }}</td>
          <th scope="row" v-if="isFirst">{{ $_t("hips") }}</th>
        </tr>
        <tr>
          <td>{{ model.user.model.shoes }}</td>
          <th scope="row" v-if="isFirst">{{ $_t("shoe") }}</th>
        </tr>
        <tr>
          <td>{{ model.user.model.hair_color.name }}</td>
          <th scope="row" v-if="isFirst">{{ $_t("hair") }}</th>
        </tr>
        <tr>
          <td>{{ model.user.model.eye_color.name }}</td>
          <th scope="row" v-if="isFirst">{{ $_t("eye") }}</th>
        </tr>
        <tr>
          <td>{{ model.user.country.name }}</td>
          <th scope="row" v-if="isFirst">{{ $_t("nationality") }}</th>
        </tr>
        <tr>
          <td>
            {{ model.user.languagesWithLevel.map((lang) => lang.name).join(", ") }}
          </td>
          <th scope="row" v-if="isFirst">{{ $_t("language") }}</th>
        </tr>
        <tr>
          <td style="direction: ltr" class="px-2">
            <block-button
              text="approve"
              icon="WMi-check-linear"
              size="large"
              class="btn__approve py-3 w-100 btn__size__16"
              :color="model.status.id === 5 ? 'cyan' : 'black'"
              :disabled="model.status.id === 5"
              height="23"
              @click.native="changeStatus(5)"
              :loading="loadingApproveBtn"
            ></block-button>
            <div class="ml-5">
              <block-button
                text="NO, NOT SUITABLE"
                :text-mode="true"
                color="red"
                class="btn__cancel btn__size__14 my-1 float-left"
                icon="WMi-cancel-linear"
                height="23"
                @click.native="changeStatus(6)"
                :loading="loadingRejectBtn"
              ></block-button>
              <a :href="`${$baseUrl}/print/${model.user.unique_id}`" target="_blank">
                <block-button
                  text="COMPOSITE"
                  :text-mode="true"
                  color="black"
                  class="btn__composite btn__size__14 float-left"
                  icon="WMi-right-chevron"
                  height="23"
                ></block-button>
              </a>
            </div>
          </td>
          <th style="vertical-align: middle" scope="row" v-if="isFirst">
            {{ $_t("actions") }}
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loadingApproveBtn: false,
      loadingRejectBtn: false,
    };
  },
  props: {
    model: {
      type: Object,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
  },
  //   computed: {
  //     ceil() {
  //       return Math.ceil(this.list.length / 5);
  //     },
  //     ceiledlist() {
  //       return this.list.splice(this.ceil - 1);
  //     },
  //   },
  methods: {
    ...mapActions("jobOffer", ["changeStatusResponseClient"]),
    async changeStatus(statusId) {
      try {
        if (statusId === 5) {
          this.loadingApproveBtn = true;
        } else {
          this.loadingRejectBtn = true;
        }
        this.model.newResponseId = statusId;
        await this.changeStatusResponseClient(this.model);
      } catch (error) {
        return error;
      } finally {
        this.loadingApproveBtn = false;
        this.loadingRejectBtn = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  border-top: none;
}
thead tr {
  text-align: center;
}
tbody tr td:first-child {
  border: none;
}
.table {
  border-left: 1px solid #707070;
}
tbody tr {
  border-top: 1px solid #e6e6e6;
}
thead:first-child tr {
  text-align: left;
}
tr td {
  text-align: center !important;
  width: 49.5% !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.model__image {
  width: 100%;
}
.model__name {
  text-align: center;
  font-size: 20px;
  font-family: "Montserrat-Light";
}
.btn__approve {
  border: 1px solid var(--color-gray) !important;
  background-color: var(--color-white) !important;
  border-radius: 0 !important;
  transition: all 0.4s;
}
.btn__approve:hover {
  border: 1px solid var(--color-gray) !important;
  background-color: var(--color-black) !important;
  color: var(--color-white) !important;
}
.btn__cancel {
  font-family: "Montserrat-Light";
}
tbody tr {
  height: 49px;
}
tbody tr th {
  position: relative;
  left: 1%;
}
</style>
