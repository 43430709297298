<template>
  <div class="wa__modeling__offer">
    <template>
      <v-container class="py-1 d-none d-md-block">
        <div>
          <SocialIcon />
        </div>
      </v-container>
      <Header />
      <main>
        <div class="social__divider__wrapper">
          <SocialDivider :title="$_t('title')" />
        </div>
        <v-container>
          <ModelSelectionMain :unique_id="uniqueId" />
        </v-container>
      </main>
      <footer>
        <div class="wa__home--footer">
          <site-footer :text="$_t('footerText')"></site-footer>
        </div>
      </footer>
    </template>
  </div>
</template>
<script>
import SocialDivider from "../../components/Global/Section/SocialDivider.vue";
import SocialIcon from "../../components/Global/Header/User/SocialIcon.vue";
import Header from "../../components/Global/Header/User/Header.vue";
import ModelSelectionMain from "../../components/modelSelection/Main.vue";
export default {
  components: {
    SocialDivider,
    SocialIcon,
    Header,
    ModelSelectionMain,
  },
  data() {
    return {
      uniqueId: this.$route.params.uniqueId,
    };
  },
};
</script>
<style scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1640px !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}
.wa__modeling__offer {
  background-color: #f5f3f3 !important;
}
.social__divider__wrapper {
  width: max-content;
  margin: 30px auto 0 auto;
}
.offer__title {
  font-size: 48px !important;
  text-transform: uppercase;
}
.offer__base {
  padding: 30px 65px;
}
.offer__base p {
  font-size: 18px;
}
.social__hr {
  border-top: 2px solid #928c8c;
}
/* form */
.registered__on {
  font-size: 18px;
}
.registered__on span {
  color: #ac3773;
}
.btn-intended {
  font-family: "montserrat-light";
  border: 1px solid var(--color-gray) !important;
  font-weight: bold;
  font-size: 20px;
}
.font__18 {
  font-size: 18px;
}
</style>
<style>
@media (min-width: 1200px) {
  .container {
    max-width: 1640px !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
  .header__container {
    max-width: 960px !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1640px !important;
  }
}
</style>
