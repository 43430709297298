import {pickBy} from 'lodash';
export default class DynamicFilter {

    constructor(arrayData, filterObject) {
        this.arrayData = arrayData;
        // remove empty values from filter object
        this.filterObject = this.removeEmpty(filterObject);
        for (const name in this.filterObject) {
            if (Object.prototype.hasOwnProperty.call(this.filterObject, name)) {
                if (this.filterObject[name] && typeof this.filterObject[name].type !== 'undefined') {
                    this[this.filterObject[name].type](name);
                } else {
                    this[name](this.filterObject[name]);
                }
            }
        }
        return this.arrayData;
    }

    like(name) {
        const value = this.filterObject[name].val;
        const location = this.filterObject[name].location.split(":");
        if (location && value !== null) {
            return this.arrayData = this.arrayData.filter(data => {
                return this.getLocation(data, location).indexOf(value) !== -1;
            })
        }
        this.arrayData = this.arrayData.filter(data => data[name].indexOf(value) !== -1);
    }

    between(name) {
        const value1 = this.filterObject[name].val1;
        const value2 = this.filterObject[name].val2;
        const location = this.filterObject[name].location.split(":");
        if (value1 && value2) {
            if (typeof value1 === 'number' && typeof value2 === 'number') {
                this.arrayData = this.arrayData.filter(data => {
                    if (location) {
                        return value1 <= this.getLocation(data, location) && this.getLocation(data, location) <= value2
                    } else {
                        return value1 <= data[name] && data[name] <= value2
                    }
                });
            } else {
                let { from, to } = this.checkDateAndParse(value1, value2);
                this.arrayData = this.arrayData.filter(data => {
                    const check = Date.parse(data[name]);
                    return check >= from && check <= to;
                });
            }

        } else if (value1) {
            this.greater(name);

        } else if (value2) {
            this.less(name);
        }

    }

    checkDateAndParse(value1, value2) {
        let from, to = null;
        if (value1 && value1.length <= 10) {
            from = Date.parse(value1 + 'T00:00:00');
        } else {
            from = Date.parse(value1);
        }

        if (value2 && value2.length <= 10) {
            to = Date.parse(value2 + 'T23:59:59');
        } else {
            to = Date.parse(value1);
        }

        return { from, to };
    }

    greater(name) {
        const value1 = this.filterObject[name].val1;
        const location = this.filterObject[name].location.split(":");
        if (typeof value1 === 'number') {
            if (location) {
                return this.arrayData = this.arrayData.filter(data => value1 <= this.getLocation(data, location));
            }
            this.arrayData = this.arrayData.filter(data => value1 <= data[name]);
        } else {
            let { from } = this.checkDateAndParse(value1);

            this.arrayData = this.arrayData.filter(data => {
                const check = Date.parse(data[name]);
                return check >= from;
            });
        }
    }

    less(name) {
        const value2 = this.filterObject[name].val2;
        const location = this.filterObject[name].location.split(":");
        if (typeof value2 === 'number') {
            if (location) {
                return this.arrayData = this.arrayData.filter(data => this.getLocation(data, location) <= value2);
            }
            this.arrayData = this.arrayData.filter(data => data[name] <= value2);
        } else {
            let { to } = this.checkDateAndParse(value2);

            this.arrayData = this.arrayData.filter(data => {
                const check = Date.parse(data[name]);
                return check <= to;
            });
        }
    }

    in(name) {
        let value = this.filterObject[name].val;
        const key = this.filterObject[name].key ? this.filterObject[name].key : 'id';
        const location = this.filterObject[name].location && this.filterObject[name].location.split(":");
        if (Array.isArray(value) && !value.length) {
            value = null;
            return this.arrayData;
        }
        this.arrayData = this.arrayData.filter(data => {
            let locationObj = location && this.getLocation(data, location);
            if (location && typeof locationObj === 'object' && value !== null && Array.isArray(locationObj)) {
                return locationObj.map(item => item[key]).filter(item => value.includes(item)).length > 0;
            } else if (location && !['object', 'undefined'].includes(typeof locationObj) && !Array.isArray(locationObj)) {
                return value.includes(locationObj)
            } else if (!location && typeof data[name] === 'object') {
                console.log((data[name] && value) && data[name].map(item => item[key]).filter(item => value.includes(item)).length > 0);
                return (data[name] && value) && data[name].map(item => item[key]).filter(item => value.includes(item)).length > 0;
            }
        });
    }
    // get value from nested object &&  location must be array
    getLocation(obj, location) {
        return location.reduce(function (obj, prop) {
            return obj && obj[prop];
        }, obj)
    }
    // remove Empty value
    removeEmpty(obj) {
        return pickBy(obj, function (value) {
            return !(value["val"] === null);
        });
    }
}
