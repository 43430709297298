<template>
  <basic-modal
    @close="closeModal"
    @open="openModal"
    width="1000"
    transition="slide-x-transition"
  >
    <template #header>
      <divider
        title="Advance FILTERS"
        sub-title="BE MORE SPECIFIC"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="px-6 px-md-14 mt-8 mb-11">
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              :items="getCountries"
              item-text="name"
              item-value="id"
              :label="$_t('filter.nationality')"
              v-model="filter.nationality.val"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              :items="getLanguages"
              item-text="name"
              item-value="id"
              :label="$_t('filter.language')"
              v-model="filter.language.val"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              :items="getEyeColors"
              item-text="name"
              item-value="id"
              :label="$_t('filter.eye')"
              v-model="filter.eye.val"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              :items="getHairColors"
              item-text="name"
              item-value="id"
              :label="$_t('filter.hair')"
              v-model="filter.hair.val"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <!-- age -->
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.ageMin')"
              v-model.number="filter.age.val1"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.ageMax')"
              v-model.number="filter.age.val2"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
          <!-- height -->
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.heightMin')"
              v-model.number="filter.height.val1"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.heightMax')"
              v-model.number="filter.height.val2"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- bust -->
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.bustMin')"
              v-model.number="filter.bust.val1"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.bustMax')"
              v-model.number="filter.bust.val2"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
          <!-- waist -->
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.waistMin')"
              v-model.number="filter.waist.val1"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.waistMax')"
              v-model.number="filter.waist.val2"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- hips -->
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.hipsMin')"
              v-model.number="filter.hips.val1"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.hipsMax')"
              v-model.number="filter.hips.val2"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
          <!-- shoe -->
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.shoeMin')"
              v-model.number="filter.shoes.val1"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              item-text="name"
              item-value="id"
              :label="$_t('filter.shoeMax')"
              v-model.number="filter.shoes.val2"
              outlined
              dense
              color="SonicSilver"
              class="no-error-msg pt-0 mt-0"
              multiple
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-7 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel btn__size__18"
          :text="$_t('filter.clear')"
          icon="WMi-cancel-linear"
          @click.native="clear"
          height="30"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign site__button width-200 ml-5 btn__size__16"
          :text="$_t('filter.execute')"
          @click.native="send"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import dynamicFilter from "@/utils/filter/dynamicFilter";
import { cloneDeep } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
const defaultFilter = {
  language: {
    type: "in",
    val: null,
    location: "user:languagesWithLevel",
  },
  nationality: {
    type: "in",
    val: null,
    location: "user:country_id",
  },
  hair: {
    type: "in",
    val: null,
    location: "user:model:hair_color_id",
  },
  eye: {
    type: "in",
    val: null,
    location: "user:model:eye_color_id",
  },
  age: {
    type: "between",
    val1: null,
    val2: null,
    location: "user:age",
  },
  height: {
    type: "between",
    val1: null,
    val2: null,
    location: "user:model:height",
  },
  bust: {
    type: "between",
    val1: null,
    val2: null,
    location: "user:model:bust",
  },
  waist: {
    type: "between",
    val1: null,
    val2: null,
    location: "user:model:waist",
  },
  hips: {
    type: "between",
    val1: null,
    val2: null,
    location: "user:model:hips",
  },
  shoes: {
    type: "between",
    val1: null,
    val2: null,
    location: "user:model:shoes",
  },
};
export default {
  name: "modal_advance_filter",
  data: () => ({
    models: [],
    filter: cloneDeep(defaultFilter),
  }),
  methods: {
    ...mapActions("eyeColor", ["loadEyeColors"]),
    ...mapActions("hairColor", ["loadHairColors"]),
    ...mapActions("country", ["loadCountries"]),
    ...mapActions("language", ["loadLanguages"]),
    ...mapMutations("jobOffer", ["SET_FILTER_MODEL_SELECTION"]),
    openModal({ models }) {
      this.models = models;
      if (Object.keys(this.getFiltersModelSelection).length) {
        this.filter = this.getFiltersModelSelection;
      }
    },
    closeModal() {
      this.SET_FILTER_MODEL_SELECTION(this.filter);
    },
    send() {
      let newModels = new dynamicFilter(this.models, this.filter);
      this.$emit("filter", newModels);
      this.$_closeModal();
    },
    clear() {
      this.$emit("clear");
      this.filter = cloneDeep(defaultFilter);
      this.$_closeModal();
    },
  },
  computed: {
    ...mapGetters("eyeColor", ["getEyeColors"]),
    ...mapGetters("hairColor", ["getHairColors"]),
    ...mapGetters("country", ["getCountries"]),
    ...mapGetters("language", ["getLanguages"]),
    ...mapGetters("jobOffer", ["getFiltersModelSelection"]),
  },
  created() {
    this.loadCountries();
    this.loadLanguages();
    this.loadHairColors();
    this.loadEyeColors();
  },
};
</script>
<style scoped>
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
</style>
