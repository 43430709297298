var render = function render(){var _vm=this,_c=_vm._self._c;return _c('list',[_c('div',{staticClass:"px-3 px-md-12"},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"color":"black","centered":"","slider-color":"black"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"model__selections__tab"},[_vm._v("LIST VIEW")]),(
              !_vm.$vuetify.breakpoint.sm &&
              !_vm.$vuetify.breakpoint.xs &&
              !_vm.$vuetify.breakpoint.md
            )?_c('v-tab',{staticClass:"model__selections__tab"},[_vm._v("COMPARE VIEW")]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-center"},[_c('div',[_c('div',{staticClass:"mt-4 d-flex flex-wrap align-center"},[_c('div',{staticClass:"text-uppercase mr-3 shooting__date"},[_vm._v(" Shooting date: ")]),_c('div',[_c('v-item-group',{model:{value:(_vm.filter.dates.val),callback:function ($$v) {_vm.$set(_vm.filter.dates, "val", $$v)},expression:"filter.dates.val"}},_vm._l((_vm.shootingDates),function(item){return _c('v-item',{key:item.id,staticClass:"mr-2",attrs:{"value":item.date},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('block-button',{class:[
                          active ? 'active-btn' : '',
                          'btn-intended mb-2  pa-4 btn__size__14',
                        ],style:(active
                            ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                            : ''),attrs:{"text":item.text,"height":"27"},nativeOn:{"click":function($event){return toggle.apply(null, arguments)}}})]}}],null,true)})}),1)],1)])]),_c('div',[_c('block-button',{staticClass:"btn__size__16 btn__filter__advance",attrs:{"text":"Advance FILTER","text-mode":true,"height":"27","icon":"WMi-filter","color":"black","bg-color":"white"},nativeOn:{"click":function($event){return _vm.$_openModal('advance_filter', { models: _vm.models })}}})],1)]),_c('v-tab-item',[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"items-per-page":5},scopedSlots:_vm._u([{key:"item.first_name",fn:function({ item }){return [_c('v-row',{staticClass:"wa__table"},[_c('v-col',{attrs:{"cols":"6","md":"5"}},[_c('div',{staticClass:"wa__model__name--image"},[_c('figure',[(item.thumbnail)?_c('img',{attrs:{"src":item.thumbnail,"alt":"image"}}):_c('img',{attrs:{"src":"https://via.placeholder.com/55x65","alt":"image"}})])])]),_c('v-col',{staticClass:"bg-white px-0",attrs:{"cols":"6","md":"7"}},[_c('div',{staticClass:"d-flex align-center justify-content-start h-100 special__border"},[_c('div',[_c('div',{staticClass:"wa__f__m__r text-uppercase d-flex align-center model__selection__name"},[_vm._v(" "+_vm._s(_vm.$_changeModelProperty(item, "first_name"))+" ")]),_c('div',{staticClass:"d-flex"},[_c('div',[_c('block-button',{class:`wa__f__m__r btn__approve pa-2  ${
                                    item.status.id === 5
                                      ? 'cyan--text'
                                      : 'black--text'
                                  }`,attrs:{"text":"APPROVE","disabled":item.status.id === 5,"loading":item.loadingApproveBtn},nativeOn:{"click":function($event){return _vm.changeStatus(item, 5)}}})],1),_c('div',{staticClass:"ml-1"},[_c('v-btn',{attrs:{"text":"","icon":"","small":"","color":"red lighten-2","loading":item.loadingRejectBtn}},[_c('v-icon',{staticClass:"WMi-cancel-linear",attrs:{"small":"","color":"#EE3552"},nativeOn:{"click":function($event){return _vm.changeStatus(item, 6)}}})],1)],1)]),_c('a',{attrs:{"href":`${_vm.$baseUrl}/print/${item.unique_id}`,"target":"_blank"}},[_c('block-button',{staticClass:"btn__composite btn__size__10 float-left pl-0",attrs:{"text":"COMPOSITE","text-mode":true,"color":"black","icon":"WMi-right-chevron","height":"23"}})],1)])])])],1)]}}])})],1)],1)],1),(
              !_vm.$vuetify.breakpoint.sm &&
              !_vm.$vuetify.breakpoint.xs &&
              !_vm.$vuetify.breakpoint.md
            )?_c('v-tab-item',[(_vm.filterModels.length)?_c('v-row',{staticClass:"mt-4 compare__list"},_vm._l((_vm.filterModels),function(model,i){return _c('v-col',{key:i,staticClass:"mb-2 pa-0 compare__list--item",attrs:{"cols":"12","md":i % 5 === 0 ? 4 : 2}},[_c('CompareListItem',{attrs:{"model":model,"is-first":i % 5 === 0}})],1)}),1):_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"text-center"},[_vm._v("There are no items to display")])])],1):_vm._e()],1)],1)],1)],1),[_c('div',{staticClass:"text-center"},[(_vm.isModal('modal_advance_filter'))?_c('AdvanceFilterModal',{on:{"filter":function($event){return _vm.replaceModel($event)},"clear":_vm.clearFilter}}):_vm._e()],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }